<template>
  <div class="payment payment-completed">
    <v-container class="d-flex flex-column scroll-container">
      <CategoryTitle :category="category" class="px-0" />
      <v-row>
        <v-col cols="12" md="9" order="last" order-sm="first">
          <v-card color="grey lighten-3" flat class="card-1">
            <v-card-title>
              <span class="step">1</span>{{ $t("payment.block.1.title") }}
            </v-card-title>
            <v-card-text>
              <PaymentOrderInfo class="mt-4" />
              <PackagesTypeList class="d-none" />
              <PaymentInvoice
                @billingFormCompleted="billingFormCompleted"
                class="d-none"
              />
            </v-card-text>
          </v-card>

          <v-card color="grey lighten-3" flat class="card-2 d-none">
            <v-card-title>
              <span class="step">2</span>{{ $t("payment.block.2.title") }}
            </v-card-title>
            <v-card-text>
              <PaymentCheckUserData class="mt-5" />
              <PaymentCheckEmail2 class="mt-8 d-none" />
              <PaymentLotteryForm
                class="d-none"
                v-if="lotteryEnabled && !needInvoice"
                @deliveryServiceIds="1"
                @personInfoTypeId="21"
              />
            </v-card-text>
          </v-card>

          <v-card color="grey lighten-3" flat class="card-3 d-none">
            <v-card-title>
              <span class="step">3</span>{{ $t("payment.block.3.title") }}
            </v-card-title>
            <v-card-text>
              <TicketTypeList
                :cart="cart"
                mode="cart"
                :isPaymentDisabled="
                  !billingFormCompletedInternal && invoiceNeeded
                "
              />
            </v-card-text>
          </v-card>

          <v-card color="grey lighten-3" flat class="card-4">
            <v-card-title>
              <span class="step">2</span>{{ $t("payment.block.4.title") }}
            </v-card-title>
            <v-card-text>
              <!--TODO: gestione server side dell'invoice per pagamento selezionato -->
              <TigrosPaymentTypeList
                class="mt-1"
                :cart="cart"
                mode="cart"
                :isPaymentDisabled="
                  !billingFormCompletedInternal && invoiceNeeded
                "
                :needInvoice="invoiceNeeded"
                :options="paymentTypeListOptions"
                :defaultPaymentType="-1"
                :autoSelectPaymentType="false"
              />

              <div
                class="accept-rules-and-privacy font-weight-bold text-body-2"
                v-if="$te('payment.accept')"
                v-html="$t('payment.accept')"
              ></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="3"
          order="first"
          order-sm="last"
          v-if="cart.shippingAddress"
        >
          <CheckoutSummary
            :orderCart="cart"
            :isEditable="false"
            class="d-block"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<style lang="scss">
.payment {
  .v-card {
    margin-bottom: 12px;
    background-color: var(--v-grey-lighten3);
    .v-card__title {
      color: var(--v-primary-base);
      font-weight: bold;
      font-size: 15px;
      word-break: break-word;
      display: block;
    }
    .step {
      background-color: $secondary;
      color: $white;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      font-size: 20px;
      display: inline-block;
      text-align: center;
      margin-right: 12px;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import TigrosPaymentTypeList from "@/components/payment/TigrosPaymentTypeList.vue";
import TicketTypeList from "@/components/payment/TicketTypeList.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import PaymentOrderInfo from "@/components/payment/PaymentOrderInfo.vue";
import PaymentCheckUserData from "@/components/payment/PaymentCheckUserData.vue";
import PaymentCheckEmail2 from "@/components/payment/PaymentCheckEmail2.vue";
import PaymentLotteryForm from "@/components/payment/PaymentLotteryForm.vue";
import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import recaptcha from "~/mixins/recaptcha";

import AnalyticsService from "~/service/analyticsService";

import { mapCartInfo } from "~/service/ebsn";
import { mapState } from "vuex";

export default {
  name: "Payment",
  data() {
    return {
      paymentTypeList: [],
      isPaymentDisabled: false,
      billingFormCompletedInternal: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        mainBackgroundColor: "grey lighten-3",
        titleStyle: "default--text text-uppercase payment-type-list-title",
        back: {
          label: "Torna Indietro",
          class: "paymenbt-back-button",
          show: true
        }
      },
      lotteryEnabled: true,
      lotteryEnabledDate: "2021-02-01"
    };
  },
  components: {
    TigrosPaymentTypeList,
    CategoryTitle,
    CheckoutSummary,
    PaymentInvoice,
    TicketTypeList,
    PaymentOrderInfo,
    PackagesTypeList,
    PaymentCheckUserData,
    PaymentCheckEmail2,
    PaymentLotteryForm
  },

  mixins: [login, cartInfo, categoryMixins, recaptcha],

  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ needInvoice: "need_invoice" }),
    invoiceNeeded() {
      return this.needInvoice === "true" ? true : false;
    }
  },
  methods: {
    enableLottery(date) {
      var minDate = new Date(date).getTime();
      if (this.cart.timeslot && this.cart.timeslot.date) {
        var deliveryDate = new Date(this.cart.timeslot.date).getTime();
        if (deliveryDate - minDate < 0) {
          this.lotteryEnabled = false;
        }
      }
    },
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    },
    goBack() {
      this.$router.push("/checkout");
    }
  },
  created() {
    AnalyticsService.beginCheckout(this.cart);
    this.enableLottery(this.lotteryEnabledDate);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.cart && vm.cart.totalItems > 0) {
        next();
      } else {
        next("/");
      }
    });
  }
};
</script>
